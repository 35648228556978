//** instantiate easy timer */
let timerInstance = new easytimer.Timer();

//** initialize materialize modal */
document.addEventListener('DOMContentLoaded', function () {
  let elems = document.querySelectorAll('.modal');
  let instances = M.Modal.init(elems);
});

let randomColors = [
  '#6ab14b',
  '#e56969',
  '#c602c6',
  '#c8c802',
  '#efc53e',
  '#aea2af',
  '#27c7c7',
  '#66b3ed',
  '#c299c3',
  '#ec3c65',
  '#2b908f',
];

const getElementById = (id) => {
  return document.getElementById(id);
};

let tableRows = document.getElementsByTagName('td');
let startTimer = getElementById('startSpinner');
let stopTimer = getElementById('stopSpinner');
let header = getElementById('header');
let timer = getElementById('timer');
let countDown = getElementById('countDown');
let namesTextArea = getElementById('namesTextArea');
let btnSaveNames = getElementById('btnSaveNames');
let btnClearNames = getElementById('btnClearNames');
let btnClearModal = getElementById('btnClearModal');

let allNames = [];
let rows = [];

btnSaveNames.addEventListener('click', () => {
  const splittedNames = namesTextArea.value.split(',');

  allNames = Array.from(splittedNames);

  const element = document.getElementById('table-body');
  let tableRow = document.createElement('tr');

  let counter = 0;
  allNames.forEach((t, i) => {
    if (i % 5 === 0 && counter > -1) {
      tableRow = document.createElement('tr');
      const para = document.createElement('td');
      const node = document.createTextNode(t.trim());
      para.appendChild(node);
      tableRow.appendChild(para);
      element.appendChild(tableRow);
      counter = 0;
    } else if (i % 5 !== 0 && counter === 0) {
      const para = document.createElement('td');
      const node = document.createTextNode(t.trim());
      para.appendChild(node);
      tableRow.appendChild(para);
      element.appendChild(tableRow);
      counter += 1;
    } else if (i % 5 !== 0 && counter <= 5) {
      const para = document.createElement('td');
      const node = document.createTextNode(t.trim());
      para.appendChild(node);
      tableRow.appendChild(para);
      element.appendChild(tableRow);
      counter += 1;
    }
  });

  rows = Array.from(tableRows);
  rows.forEach((td) => {
    td.style.backgroundColor = randomColors[chance.integer({min: 0, max: randomColors.length - 1})];
  });
});

const clearState = () => {
  const element = document.getElementById('table-body');
  element.innerHTML = '';
  timer.value = '';
};

btnClearNames.addEventListener('click', () => {
  clearState();
});

btnClearModal.addEventListener('click', () => {
  namesTextArea.value = '';
});

let randomName = chance.integer({min: 0, max: rows.length});
let audio1 = new Audio('audio1.mp3');
let audio2 = new Audio('audio2.mp3');

const pickWinner = (timer) => {
  let timeout;
  if (!timer) {
    timeout = 5000;
  } else {
    timeout = timer * 1000;
  }

  //** counter  starter */
  timerInstance.start({
    startValues: {seconds: 0},
    target: {seconds: timeout / 1000},
    precision: 'secondTenths',
  });

  //** count down timer */
  timerInstance.addEventListener('secondTenthsUpdated', () => {
    countDown.innerText = timerInstance.getTimeValues().toString(['minutes', 'seconds', 'secondTenths']);
  });

  audio2.pause();
  audio1.play();

  let randomColors2 = ['#e80ff8', '#ddd068', '#4d913d', '#ff74b1', '#d57373'];

  const timeoutId = setInterval(() => {
    let randomName = chance.integer({min: 0, max: rows.length - 1});
    rows[randomName].style.backgroundColor = '#fff';
    rows[randomName].style.backgroundColor =
      randomColors2[chance.integer({min: 0, max: randomColors2.length - 1})];
    header.innerText = rows[randomName].textContent;
  }, 100);

  setTimeout(() => {
    audio1.pause();
    audio2.play();
    clearInterval(timeoutId);
  }, timeout);

  setTimeout(() => {
    audio2.pause();
  }, timeout + 10000);
};

startTimer.addEventListener('click', () => {
  pickWinner(timer.value);
});
